import { KjUploader } from '@kajabi/kj-uploader';
import { datadogLogs, initializeDatadogLogs } from 'common/components/Datadog/BrowserLogger';
import { logAmplitudeEvent } from 'common/components/Amplitude/AmplitudeDataHelper';

const SERVICE_NAME = 'kj-uploader';

document.addEventListener('DOMContentLoaded', () =>
  initializeDatadogLogs({ silentMultipleInit: true })
);

window.initKjUploader = function initUploader(config) {
  return new KjUploader({
    ...config,
    onBeforeUpload: withTrackingEachFile(config.onBeforeUpload,
      (filePayload) => {
        datadogLogs.logger.info(...datadogEvent('File onBeforeUpload', filePayload, config.loggerContext));
        logAmplitudeEvent(...amplitudeEvent('Upload Started', filePayload, config.loggerContext));
      }
    ),
    onComplete: withTrackingEachFile(config.onComplete,
      (filePayload) => {
        datadogLogs.logger.info(...datadogEvent('File onComplete', filePayload, config.loggerContext));
      }
    ),
    onSuccess: withTrackingEachFile(config.onSuccess,
      (filePayload) => {
        datadogLogs.logger.info(...datadogEvent('File onSuccess', filePayload, config.loggerContext));
        logAmplitudeEvent(...amplitudeEvent('Upload Successful', filePayload, config.loggerContext));
      }
    ),
    onError: withTrackingEachFile(config.onError,
      (filePayload) => {
        datadogLogs.logger.error(...datadogEvent('File onError', filePayload, config.loggerContext));
        logAmplitudeEvent(...amplitudeEvent('Upload Failed', filePayload, config.loggerContext));
      }
    ),
    onFileRemoved: withTrackingEachFile(config.onFileRemoved,
      (filePayload) => {
        datadogLogs.logger.info(...datadogEvent('File onFileRemoved', filePayload, config.loggerContext));
        // Don't Amplitude track if upload was not started
        const { file: { uploadAt, isSuccessful, isFailed } } = filePayload;
        if (!uploadAt) return;
        // or if upload was started but has finished (has successful/failed status)
        if (uploadAt && (isSuccessful || isFailed)) return;
        logAmplitudeEvent(...amplitudeEvent('Upload Cancelled', filePayload, config.loggerContext));
      }
    ),
  });
};

function withTrackingEachFile(
  callbackFn = defaultFn,
  fileTrackingHandler = defaultFn,
) {
  return (response) => {
    const result = callbackFn(response);

    if (Array.isArray(response.files)) {
      response.files.forEach(file =>
        fileTrackingHandler({
          file,
          version: response.version,
        })
      );
    }

    return result;
  };
}

function datadogEvent(message, filePayload, context) {
  const { uniqueName = 'uniqueName n/a' } = filePayload;
  return [
    `${message} - ${uniqueName}`,
    {
      kj_uploader_context: context,
      kj_uploader_details: filePayload,
      version: filePayload.version.replace('v', ''), // Remove 'v' prefix
      service: SERVICE_NAME,
    }
  ];
}

function amplitudeEvent(message, filePayload, context) {
  return [
    {
      eventName: message,
      eventProperties: {
        ...filePayload,
        context,
        version: `${SERVICE_NAME}-${filePayload.version}`,
      },
    }
  ];
}

function defaultFn() {}
