import { datadogLogs } from '@datadog/browser-logs';
import clientInfo from 'common/lib/clientInfo';

// datadogLogs initializer
//
// Datadog logger can only be initialized once on each page,
// each subsequent initializations will no-op and not use
// the newly inited config settings.
//
// Consider passing your application's `service` name within
// the event payload instead of configuring the `service` name
// in the initialization.
//
// Issue: https://github.com/DataDog/browser-sdk/issues/1777

export const initializeDatadogLogs = ({
  applicationId = '8dca32bf-6fd4-41ad-8dc8-48410773bf4f',
  clientToken = 'pubad2d2f353500d3d58c1f2b27efcdf3fc',
  serviceName = 'kajabi-admin',
  version = '1.0.0',
  forwardProdErrors,
  silentMultipleInit,
}) => {
  const environment = clientInfo.get('env');
  const appName = clientInfo.get('app_name');

  const otherConfig = {
    site: 'datadoghq.com',
    sampleRate: environment === 'test' ? 0 : 100,
    useSecureSessionCookie: true,
    // Don't forward dev or test errors to DD
    forwardErrorsToLogs: environment === 'production' && forwardProdErrors,
    // Use the appname for "production" environments, e.g. kajabi-storefronts-qa5
    env: appName || environment,
    version,
    silentMultipleInit,
  };

  datadogLogs.init({
    applicationId,
    clientToken,
    service: serviceName,
    ...otherConfig,
  });
};

export { datadogLogs };
