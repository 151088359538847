export class ClientInfo {
  info() {
    return window.CLIENT_INFO || {};
  }

  get(item) {
    const keys = item.split('.');

    let obj = this.info();

    keys.forEach((key) => {
      let temp;
      if (obj) {
        temp = obj[key];
      }

      obj = temp;
    });

    return obj;
  }

  featureActive(feature) {
    return (this.get('features') || {})[feature];
  }

  helpArticle(articleId) {
    return (this.get('helpArticles') || {})[articleId];
  }
}

export default new ClientInfo();
